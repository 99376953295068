@tailwind base;
@tailwind components;
@tailwind utilities;
/* styles/globals.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  scroll-behavior: smooth;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
